"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.B2bStockScreen = void 0;
var repository_1 = require("@codebuild/glaze/libs/repository/repository");
var field_1 = require("@codebuild/sprinkles/libs/form/field");
var form_builder_1 = require("@codebuild/sprinkles/libs/form/form-builder");
var form_control_1 = require("@codebuild/sprinkles/libs/form/form-control/form-control");
var abstract_component_1 = require("@codebuild/sprinkles/libs/libs/abstract.component");
var trans_1 = require("@codebuild/sprinkles/libs/translation/trans");
var button_1 = require("@codebuild/sprinkles/modules/codebuild/button/react/button");
var select_input_form_control_type_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/form-control-types/select-input.form-control-type");
var select_input_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/react/select-input");
var table_1 = require("@codebuild/sprinkles/modules/codebuild/table/table");
var table_header_cell_1 = require("@codebuild/sprinkles/modules/codebuild/table/table-header-cell");
var table_header_row_1 = require("@codebuild/sprinkles/modules/codebuild/table/table-header-row");
var lodash_1 = require("lodash");
var React = require("react");
var operators_1 = require("rxjs/operators");
var list_search_component_1 = require("../../common/components/list-search/list-search.component");
var pagination_list_control_1 = require("../../common/components/repository-list/controls/pagination-list-control");
var pagination_control_1 = require("../../common/components/repository-list/controls/pagination.control");
var list_empty_1 = require("../../common/components/repository-list/empty/list-empty");
var list_loader_1 = require("../../common/components/repository-list/loader/list-loader");
var repository_list_1 = require("../../common/components/repository-list/repository-list");
var filterable_tree_select_1 = require("../../common/components/tree-select/filterable-tree-select");
var form_1 = require("../../components/form/form");
var history_storage_tree_select_form_control_type_1 = require("../b2b-stock-history/history-storage-tree-select.form-control-type");
require("./b2b-stock.screen.scss");
var stock_item_1 = require("./libs/stock-item");
var B2bStockScreen = (function (_super) {
    __extends(B2bStockScreen, _super);
    function B2bStockScreen() {
        var _this = this;
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
        _this = _super.apply(this, __spreadArray([], __read(arguments), false)) || this;
        _this.repository = repository_1.Repository.use('default');
        _this.control = new pagination_list_control_1.PaginationListControl({
            defaultData: {
                limit: 20,
                page: (_c = (_b = (_a = _this.props.route) === null || _a === void 0 ? void 0 : _a.query) === null || _b === void 0 ? void 0 : _b._page) !== null && _c !== void 0 ? _c : 0,
                query: (_f = (_e = (_d = _this.props.route) === null || _d === void 0 ? void 0 : _d.query) === null || _e === void 0 ? void 0 : _e.query) !== null && _f !== void 0 ? _f : '',
                sort: (_h = (_g = _this.props.route) === null || _g === void 0 ? void 0 : _g.sort) !== null && _h !== void 0 ? _h : { createdAt: -1 }
            },
        });
        _this.form = new form_builder_1.FormBuilder({
            fields: {
                storages: new field_1.Field({
                    name: 'storages',
                    id: 'storages',
                    label: 'Tárhely',
                    value: ((_k = (_j = _this.control) === null || _j === void 0 ? void 0 : _j.getQuery()) === null || _k === void 0 ? void 0 : _k.storages) || [],
                    optionsEndpoint: '/b2b/storage',
                    optionsMap: function (response) { var _a; return (_a = response === null || response === void 0 ? void 0 : response.items) === null || _a === void 0 ? void 0 : _a.map(function (o) { return ({ name: o.title, value: o._id, key: o._id }); }); },
                    loadOptionsAfterMount: true,
                }),
                warehouse: new field_1.Field({
                    name: 'warehouse',
                    id: 'warehouse',
                    label: 'Raktár',
                    value: ((_m = (_l = _this.control) === null || _l === void 0 ? void 0 : _l.getQuery()) === null || _m === void 0 ? void 0 : _m.warehouse) || [],
                    optionsEndpoint: '/b2b/warehouse',
                    optionsMap: function (response) { var _a; return (_a = response === null || response === void 0 ? void 0 : response.items) === null || _a === void 0 ? void 0 : _a.map(function (o) { return ({ name: o.title, value: o._id, key: o.identifier }); }); },
                    loadOptionsAfterMount: true,
                }),
            }
        });
        _this.state = {
            openedRows: [],
            productIds: [],
            inventorySheetLoading: false,
        };
        return _this;
    }
    B2bStockScreen.prototype.componentDidMount = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                this.form.fields.storages.$value.pipe((0, operators_1.debounceTime)(450)).subscribe(function (v) {
                    var _a, _b;
                    if (!(v === null || v === void 0 ? void 0 : v.length)) {
                        _this.setState({ isStorageFilterActive: false });
                        _this.form.fields.warehouse.setValue(((_b = (_a = _this.control) === null || _a === void 0 ? void 0 : _a.getQuery()) === null || _b === void 0 ? void 0 : _b.warehouse) || []);
                    }
                    else {
                        _this.setState({ isStorageFilterActive: true });
                        _this.form.fields.warehouse.setValue('');
                    }
                    _this.handleChange('storages', v);
                });
                this.form.fields.warehouse.$value.subscribe(function (v) { return _this.handleChange('warehouse', v); });
                return [2];
            });
        });
    };
    B2bStockScreen.prototype.handleChange = function (property, value) {
        var currentValue = this.control.getQuery()[property];
        if (!!(0, lodash_1.isEqual)(currentValue, value)) {
            return;
        }
        this.control.set(property, value);
        this.control.page(0);
    };
    B2bStockScreen.prototype.downloadInventorySheet = function (path) {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function () {
            var storages, storagesQuery, query, currentQuery, warehouseQuery, inventorySheetLink, e_1;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _c.trys.push([0, 2, 3, 4]);
                        this.setState({ inventorySheetLoading: true });
                        storages = this.form.fields.storages.getValue();
                        storagesQuery = (storages === null || storages === void 0 ? void 0 : storages.length) ? (_a = storages === null || storages === void 0 ? void 0 : storages.map(function (s) { return "storages[]=".concat(s); })) === null || _a === void 0 ? void 0 : _a.join('&') : '';
                        query = '';
                        currentQuery = this.control.getQuery();
                        warehouseQuery = (currentQuery === null || currentQuery === void 0 ? void 0 : currentQuery.warehouse) ? "warehouse=".concat(currentQuery === null || currentQuery === void 0 ? void 0 : currentQuery.warehouse) : '';
                        if (!storagesQuery && (warehouseQuery === null || warehouseQuery === void 0 ? void 0 : warehouseQuery.length)) {
                            query = "".concat(query, "&").concat(warehouseQuery);
                        }
                        if ((_b = currentQuery === null || currentQuery === void 0 ? void 0 : currentQuery.query) === null || _b === void 0 ? void 0 : _b.length) {
                            query = "".concat(query, "&query=").concat(currentQuery.query);
                        }
                        if (!(warehouseQuery === null || warehouseQuery === void 0 ? void 0 : warehouseQuery.length) && storagesQuery) {
                            query = "".concat(query, "&").concat(storagesQuery);
                        }
                        return [4, this.repository.get("/b2b/stock/stock-inventory-sheet/".concat(path, "?").concat(query), { timeout: 600000 })];
                    case 1:
                        inventorySheetLink = _c.sent();
                        window.open(inventorySheetLink);
                        return [3, 4];
                    case 2:
                        e_1 = _c.sent();
                        console.log({ downloadInventorySheet: e_1 });
                        return [3, 4];
                    case 3:
                        this.setState({ inventorySheetLoading: false });
                        return [7];
                    case 4: return [2];
                }
            });
        });
    };
    B2bStockScreen.prototype.render = function () {
        var _this = this;
        return React.createElement("div", { className: 'B2bStockListScreen' },
            React.createElement("div", { className: 'row' },
                React.createElement("div", { className: "col-15 " },
                    React.createElement("div", { className: 'display-flex align-items-bottom mb-8' },
                        React.createElement("h2", null, "Aktu\u00E1lis rakt\u00E1rk\u00E9szlet"),
                        React.createElement("div", { className: 'ml-6' },
                            React.createElement(button_1.Button, { className: 'button-primary-outline button-size-normal', title: this.state.inventorySheetLoading ? React.createElement("i", { className: 'far fa-spinner-third fa-spin' }) : 'Leltárív - PDF', iconLeft: 'far fa-download', onClick: function () { return _this.downloadInventorySheet('pdf'); } })),
                        React.createElement("div", { className: 'ml-6' },
                            React.createElement(button_1.Button, { className: 'button-primary-outline button-size-normal', title: this.state.inventorySheetLoading ? React.createElement("i", { className: 'far fa-spinner-third fa-spin' }) : 'Leltárív - CSV', iconLeft: 'far fa-download', onClick: function () { return _this.downloadInventorySheet('csv'); } })))),
                React.createElement("div", { className: "col-9" },
                    React.createElement(list_search_component_1.ListSearchComponent, { "control$": this.control }))),
            React.createElement("div", { className: 'row mb-4' },
                React.createElement("div", { className: 'col' },
                    React.createElement("div", { className: 'filters-wrapper' },
                        React.createElement("p", { className: 'typo-small fw-600' }, "SZ\u0170R\u00C9SEK"),
                        React.createElement(form_1.Form, { className: 'row mx-0' },
                            React.createElement(form_control_1.FormControl, { field: this.form.fields.storages, type: history_storage_tree_select_form_control_type_1.HistoryStorageTreeSelectFormControlType },
                                React.createElement(filterable_tree_select_1.FilterableTreeSelect, { multi: true, clearable: true, enabledTypes: ['storage'], modalTitle: 'Tárhely választása', searchable: true, className: 'select-input-basic col-8' })),
                            React.createElement(form_control_1.FormControl, { field: this.form.fields.warehouse, type: select_input_form_control_type_1.SelectInputFormControlType },
                                React.createElement(select_input_1.SelectInput, { disabled: this.state.isStorageFilterActive, searchable: true, unclearable: false, multiple: false, className: 'select-input-basic col-8' })))))),
            React.createElement(repository_list_1.RepositoryList, { control: this.control, hideItemsWhileLoading: true, showLoading: function () { return true; }, showError: function () { return true; }, showItem: function () { return true; }, onChange: function (ctx) { return _this.handleListChange(ctx); }, repository: repository_1.Repository.use('default'), endpoint: '/b2b/stock', renderItemsWrapper: function (ctx) { return _this.renderTable(ctx); }, renderItem: function (ctx, item) { return _this.renderItem(ctx, item); }, renderEmpty: function () { return (React.createElement(list_empty_1.ListEmpty, null)); }, renderLoading: function (ctx) { return (React.createElement(list_loader_1.ListLoader, null)); } }),
            React.createElement(pagination_control_1.PaginationControl, { "control$": this.control, limitOptions: [
                    { name: 20, value: 20 },
                    { name: 50, value: 50 },
                    { name: 100, value: 100 },
                    { name: 500, value: 500 },
                ] }));
    };
    B2bStockScreen.prototype.renderTable = function (ctx) {
        var _this = this;
        return React.createElement(table_1.Table, { className: 'table-basic', renderHeader: function () { return _this.renderTableHeader(); }, renderBody: function () { return ctx.renderItems(ctx); } });
    };
    B2bStockScreen.prototype.renderItem = function (ctx, item) {
        var _this = this;
        var _a;
        return React.createElement(stock_item_1.StockItem, { key: (_a = item === null || item === void 0 ? void 0 : item.product) === null || _a === void 0 ? void 0 : _a.productId, openedRows: this.state.openedRows, warehouse: this.form.fields.warehouse, item: item, control: this.control, handleRowClick: function (sku) { return _this.handleRowClick(sku); } });
    };
    B2bStockScreen.prototype.handleRowClick = function (rowId) {
        if (this.state.openedRows.includes(rowId)) {
            var newOpened = (0, lodash_1.clone)(this.state.openedRows).filter(function (row) { return row !== rowId; });
            return this.setState({ openedRows: newOpened });
        }
        this.setState({ openedRows: __spreadArray(__spreadArray([], __read(this.state.openedRows), false), [rowId], false) });
    };
    B2bStockScreen.prototype.renderTableHeader = function () {
        return React.createElement(table_header_row_1.TableHeaderRow, { sticky: true },
            React.createElement("div", { style: { width: 32 } }),
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 15 }, this.setHeaderCell('sku'))),
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 15 }, this.setHeaderCell('ean'))),
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 34 }, this.setHeaderCell('product', true))),
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 9 }, this.setHeaderCell('amount'), { className: 'justify-content-end' })),
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 9 }, this.setHeaderCell('hecto'), { className: 'justify-content-end' })),
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 9 }, this.setHeaderCell('procurement.price'), { className: 'justify-content-end' })),
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 9 }, this.setHeaderCell('procurement.price.total'), { className: 'justify-content-end' })));
    };
    B2bStockScreen.prototype.setHeaderCell = function (p, enableSort) {
        var _this = this;
        if (enableSort === void 0) { enableSort = false; }
        return {
            sortValue: this.getSortValue(p),
            onSort: function (property, value) { return _this.handleSort(property, value); },
            enableSort: enableSort,
            title: (0, trans_1.trans)("b2b-stock.table.header.".concat(p)),
            property: p
        };
    };
    B2bStockScreen.prototype.handleSort = function (property, value) {
        this.control.sort(property, value);
    };
    B2bStockScreen.prototype.getSortValue = function (property) {
        var _a;
        return ((_a = this.control.data.sort) === null || _a === void 0 ? void 0 : _a[property]) || 0;
    };
    B2bStockScreen.prototype.handleListChange = function (ctx) {
        var _a, _b, _c;
        var total = (_a = ctx.state) === null || _a === void 0 ? void 0 : _a.total;
        var productIds = (_b = ctx.state.items) === null || _b === void 0 ? void 0 : _b.map(function (i) { return i.product.productId; });
        this.setState({
            productIds: productIds,
            total: (_c = ctx.state) === null || _c === void 0 ? void 0 : _c.total,
            openedRows: [],
        });
        if (this.props.totalChange) {
            this.props.totalChange(total);
        }
    };
    return B2bStockScreen;
}(abstract_component_1.AbstractComponent));
exports.B2bStockScreen = B2bStockScreen;
