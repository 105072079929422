"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProductAttributesForm = void 0;
var repository_1 = require("@codebuild/glaze/libs/repository/repository");
var field_1 = require("@codebuild/sprinkles/libs/form/field");
var form_builder_1 = require("@codebuild/sprinkles/libs/form/form-builder");
var form_control_1 = require("@codebuild/sprinkles/libs/form/form-control/form-control");
var required_validator_1 = require("@codebuild/sprinkles/libs/form/validators/required.validator");
var abstract_component_1 = require("@codebuild/sprinkles/libs/libs/abstract.component");
var trans_1 = require("@codebuild/sprinkles/libs/translation/trans");
var dropdown_1 = require("@codebuild/sprinkles/modules/codebuild/drpodown/react/dropdown");
var select_input_form_control_type_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/form-control-types/select-input.form-control-type");
var select_input_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/react/select-input");
var lodash_1 = require("lodash");
var React = require("react");
var form_1 = require("../../../../components/form/form");
var requiredAttributes = ['alcohol', 'capacity'];
var ProductAttributesForm = (function (_super) {
    __extends(ProductAttributesForm, _super);
    function ProductAttributesForm() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.repository = repository_1.Repository.use('default');
        _this.form = new form_builder_1.FormBuilder({
            fields: {}
        });
        _this.state = {
            attributes: _this.props.attributes || [],
            values: _this.props.values,
            editorLanguage: _this.props.editorLanguage || 'hu'
        };
        return _this;
    }
    ProductAttributesForm.prototype.componentDidMount = function () {
        this.initForm();
    };
    ProductAttributesForm.prototype.componentDidUpdate = function (prevProps, prevState, snapshot) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!!(0, lodash_1.isEqual)(prevProps.attributes, this.props.attributes)) return [3, 2];
                        return [4, this.setState({ attributes: this.props.attributes })];
                    case 1:
                        _a.sent();
                        this.initForm();
                        _a.label = 2;
                    case 2:
                        if (!!(0, lodash_1.isEqual)(prevProps.values, this.props.values)) return [3, 4];
                        return [4, this.setState({ values: this.props.values })];
                    case 3:
                        _a.sent();
                        this.initForm();
                        _a.label = 4;
                    case 4:
                        if (!!(0, lodash_1.isEqual)(prevProps.editorLanguage, this.props.editorLanguage)) return [3, 6];
                        return [4, this.setState({ editorLanguage: this.props.editorLanguage })];
                    case 5:
                        _a.sent();
                        this.initForm();
                        _a.label = 6;
                    case 6: return [2];
                }
            });
        });
    };
    ProductAttributesForm.prototype.initForm = function () {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var attributes, attributes_1, attributes_1_1, a, e_1_1;
            var e_1, _b;
            var _this = this;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        console.log(this.state.values);
                        attributes = this.state.attributes || [];
                        _c.label = 1;
                    case 1:
                        _c.trys.push([1, 6, 7, 8]);
                        attributes_1 = __values(attributes), attributes_1_1 = attributes_1.next();
                        _c.label = 2;
                    case 2:
                        if (!!attributes_1_1.done) return [3, 5];
                        a = attributes_1_1.value;
                        if (a.title.hu.includes('Alkoh')) {
                            console.log(a);
                        }
                        return [4, this.form.field(a.key, new field_1.Field({
                                placeholder: '',
                                value: (_a = this.state.values) === null || _a === void 0 ? void 0 : _a[a.key],
                                name: a.key,
                                options: ((a === null || a === void 0 ? void 0 : a.options) || [])
                                    .map(function (o) {
                                    var _a, _b;
                                    return ({
                                        key: o.selector,
                                        name: (_a = o.value) === null || _a === void 0 ? void 0 : _a[((_b = _this.state) === null || _b === void 0 ? void 0 : _b.editorLanguage) || 'hu'],
                                        value: o.selector
                                    });
                                }).sort(function (i, j) { return i.name.localeCompare(j.name, undefined, { numeric: true, sensitivity: 'base' }); }),
                                label: this.getRequiredAttribute(a).label,
                                validators: this.getRequiredAttribute(a).validator,
                            }))];
                    case 3:
                        _c.sent();
                        _c.label = 4;
                    case 4:
                        attributes_1_1 = attributes_1.next();
                        return [3, 2];
                    case 5: return [3, 8];
                    case 6:
                        e_1_1 = _c.sent();
                        e_1 = { error: e_1_1 };
                        return [3, 8];
                    case 7:
                        try {
                            if (attributes_1_1 && !attributes_1_1.done && (_b = attributes_1.return)) _b.call(attributes_1);
                        }
                        finally { if (e_1) throw e_1.error; }
                        return [7];
                    case 8:
                        this.forceUpdate();
                        return [2];
                }
            });
        });
    };
    ProductAttributesForm.prototype.getRequiredAttribute = function (attribute) {
        if (requiredAttributes.includes(attribute.key)) {
            return {
                label: React.createElement("div", null,
                    attribute.title[this.props.editorLanguage],
                    " ",
                    React.createElement("i", { className: 'color--error-6' }, "*")),
                validator: [new required_validator_1.RequiredValidator((0, trans_1.trans)('form.validation.required'))]
            };
        }
        return {
            label: React.createElement("div", null, attribute.title[this.props.editorLanguage]),
            validator: []
        };
    };
    ProductAttributesForm.prototype.render = function () {
        return React.createElement("div", null, this.renderAttributesForm());
    };
    ProductAttributesForm.prototype.renderAttributesForm = function () {
        var _this = this;
        if (!this.state.attributes.length) {
            return;
        }
        return React.createElement(form_1.Form, { className: 'row' }, (this.state.attributes || []).map(function (a) {
            var _a;
            if (!_this.form.fields[a.key]) {
                return null;
            }
            return React.createElement("div", { className: 'col-8 display-flex align-items-center', key: a.key },
                React.createElement("div", { className: "flex-fill" },
                    React.createElement(form_control_1.FormControl, { field: _this.form.fields[a.key], type: select_input_form_control_type_1.SelectInputFormControlType },
                        React.createElement(select_input_1.SelectInput, { unclearable: false, className: 'select-input-basic show-options-scroll', disabled: _this.props.disabled }))),
                ((_a = a.description) === null || _a === void 0 ? void 0 : _a.hu) && React.createElement("div", { className: "px-2" },
                    React.createElement(dropdown_1.Dropdown, { renderTrigger: function () { return React.createElement("i", { className: 'cursor-pointer fas fa-info-circle' }); }, triggerType: 'hover', renderContent: function () {
                            var _a;
                            return React.createElement("div", { className: 'p-2 drop-shadow AttributeInfoBox' },
                                React.createElement("small", null, (_a = a.description) === null || _a === void 0 ? void 0 : _a.hu));
                        } })));
        }));
    };
    ProductAttributesForm.prototype.getData = function () {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var errors, hasError, keys, _b, _c, _d, key, value;
            var e_2, _e;
            return __generator(this, function (_f) {
                switch (_f.label) {
                    case 0: return [4, this.form.validate()];
                    case 1:
                        _f.sent();
                        return [4, ((_a = this.form) === null || _a === void 0 ? void 0 : _a.getErrors())];
                    case 2:
                        errors = _f.sent();
                        hasError = false;
                        keys = [];
                        try {
                            for (_b = __values(Object.entries(errors)), _c = _b.next(); !_c.done; _c = _b.next()) {
                                _d = __read(_c.value, 2), key = _d[0], value = _d[1];
                                if (!!value.length) {
                                    keys.push(key);
                                }
                                hasError = (hasError || !!value.length);
                            }
                        }
                        catch (e_2_1) { e_2 = { error: e_2_1 }; }
                        finally {
                            try {
                                if (_c && !_c.done && (_e = _b.return)) _e.call(_b);
                            }
                            finally { if (e_2) throw e_2.error; }
                        }
                        if (hasError) {
                            return [2, { error: true, keys: keys }];
                        }
                        return [2, this.form.toJSON()];
                }
            });
        });
    };
    return ProductAttributesForm;
}(abstract_component_1.AbstractComponent));
exports.ProductAttributesForm = ProductAttributesForm;
