"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AdminMenuComponent = void 0;
var environment_1 = require("@codebuild/glaze/libs/environment");
var abstract_component_1 = require("@codebuild/glaze/libs/libs/abstract.component");
var repository_1 = require("@codebuild/glaze/libs/repository/repository");
var local_store_1 = require("@codebuild/glaze/libs/store/local.store");
var React = require("react");
var admin_menu_body_component_1 = require("./admin-menu-body.component");
var admin_menu_header_component_1 = require("./admin-menu-header.component");
require("./admin-menu.component.scss");
var AdminMenuComponent = (function (_super) {
    __extends(AdminMenuComponent, _super);
    function AdminMenuComponent() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.user = local_store_1.LocalStore.getItem('User');
        _this.repository = repository_1.Repository.use('default');
        _this.state = {
            isOpen: false
        };
        return _this;
    }
    AdminMenuComponent.prototype.render = function () {
        var _this = this;
        return React.createElement("div", { className: 'admin-menu-component' },
            this.renderLocalDevSpecificInfo(),
            React.createElement(admin_menu_header_component_1.AdminMenuHeaderComponent, { onToggle: function () { return _this.handleToggle(); } }),
            React.createElement("div", { className: "body-backdrop ".concat(this.state.isOpen ? 'open' : ''), onClick: function () { return _this.handleToggle(); } }),
            React.createElement(admin_menu_body_component_1.AdminMenuBodyComponent, __assign({}, this.props, { isOpen: this.state.isOpen })));
    };
    AdminMenuComponent.prototype.handleToggle = function () {
        var isOpen = this.state.isOpen;
        this.setState({ isOpen: !isOpen });
    };
    AdminMenuComponent.prototype.renderLocalDevSpecificInfo = function () {
        var isLocalDev = environment_1.Environment.get('isLocalDev');
        var api = environment_1.Environment.get('api');
        if (isLocalDev && !api.includes('localhost')) {
            return React.createElement("div", { className: 'w-100 p-5 position-absolute', style: { background: 'white', zIndex: 10 } },
                React.createElement("span", { className: 'fw-700' },
                    "BE CAREFUL! YOU ARE USING: ",
                    api));
        }
        return null;
    };
    return AdminMenuComponent;
}(abstract_component_1.AbstractComponent));
exports.AdminMenuComponent = AdminMenuComponent;
