"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
module.exports = {
    'stock-income.list.main.title': 'Betárolások',
    'stock-income.list.create.button': 'Új betárolás',
    'stock-income.list.table.header.identifier': 'azonosító',
    'stock-income.list.table.header.supplier.name': 'beszállító cég',
    'stock-income.list.table.header.supplierAddress': 'beszállító telephelye',
    'stock-income.list.table.header.bko': 'bko',
    'stock-income.list.table.header.status': 'Státusz',
    'stock-income.list.table.header.totalAmount': 'mennyiség',
    'stock-income.list.table.header.creator': 'létrehozó',
    'stock-income.list.table.header.createdAt': 'létrehozva',
    'stock-income.create.title': 'Betárolás',
    'b2b-stock-income.status.badge.draft': 'Vázlat',
    'b2b-stock-income.status.badge.executed': 'Betárolva',
    'stock-income.toast.success.execute': 'Sikeresen betárolva',
    'stock-income.toast.success.reopen': 'Sikeresen lejavítva',
    'stock-income.toast.success.delete': 'Betárolás törlése',
    'stock-income.create.form.supplier.label': 'Partner',
    'stock-income.create.form.supplier-depot.label': 'Partner telephelye',
    'stock-income.create.form.bko.label': 'Beérkezett bizonylat száma',
    'stock-income.create.form.pretence-code.label': 'Jogcímkód',
    'stock-income.create.form.depot.label': 'Telephely',
    'stock-income.create.form.isTransaction.label': 'Winehub tárol be',
    'stock-income.create.form.isReturn.label': 'Visszáru',
    'stock-income.create.form.warehouse.label': 'Raktár',
    'stock-income.create.form.comment.label': 'Megjegyzés',
    'stock-income.create.form.lot.label': 'LOT/NÉBIH',
    'stock-income.create.form.isForeign.label': 'EMCS',
    'stock-income.create.form.amount.label': 'db',
    'stock-income.create.form.attachments.label': 'Csatolmányok',
    'stock-income.create.form.bkoPDF.label': 'BKO',
    'stock-income.create.product-list.title': 'Termékek',
    'stock-income.create.product-list.add.button': 'Új termékek hozzáadása',
    'stock-income.create.table.header.product': 'Termék',
    'stock-income.create.table.header.storage': 'tárhely',
    'stock-income.create.table.header.lot': 'lot/nébih',
    'stock-income.create.table.header.amount': 'db',
    'stock-income.create.table.header.delete': React.createElement("i", { className: 'fas fa-trash' }),
    'stock-income.create.delete.product.success.toast': 'A terméket sikeresen eltávolítottuk',
    'stock-income.create.success.toast': 'A beszerzést sikeresen létrehoztuk',
    'stock-income.update.success.toast': 'A beszerzést sikeresen frissítettük',
    'stock-income.create.error.UnknownSupplier': 'Ismeretlen beszállító',
    'stock-income.create.error.CustomerPartnerMustBeWinehubSupplier': 'A partner nem Winehub Beszállító Partner',
    'stock-income.add-product.modal.title': 'Termék hozzáadása',
    'stock-income.add-product.modal.form.field.product.label': 'Válassz egy terméket',
    'stock-income.delete-product.modal.title': 'Termék törlése',
    'stock-income.delete-product.modal.text': 'Biztosan törlöd a terméket az összeállításból?',
    'stock-income.delete-product.modal.close.button': 'Mégsem',
    'stock-income.delete-product.modal.add-product.button': 'Igen',
    'stock-income.select-storage.modal.title': 'Tárhely választása',
    'stock-income.select-storage.modal.form.field.product.label': 'Tárhely',
    'stock-income.add-product.modal.close.button': 'Mégsem',
    'stock-income.add-product.modal.add-product.button': 'Hozzáadás',
    'stock-income.form.missing.required': 'A betárolás mentése nem sikerült. Kötelező adatok hiányoznak!',
    'stock-income.view.title': 'Betárolás',
    'stock-income.view.data.return': 'Visszáru',
    'stock-income.view.data.supplier': 'Beszállító partner',
    'stock-income.view.data.supplier.address': 'Beszállító partner telephelye',
    'stock-income.view.data.bko': 'Beérkezett bizonylat száma',
    'stock-income.view.data.pretenceCode': 'Jogcímkód',
    'stock-income.view.data.depot': 'Telephely',
    'stock-income.view.data.warehouse': 'Raktár',
    'stock-income.view.data.comment': 'Megjegyzés',
    'stock-income.view.data.isForeign': 'EMCS',
    'stock-income.view.data.products': 'Termékek',
    'stock-income.view.table.header.product': 'Termék',
    'stock-income.view.table.header.storage': 'tárhely',
    'stock-income.view.table.header.lot': 'lot/nébih',
    'stock-income.view.table.header.amount': 'db',
    'stock-income.view.control.status.title': 'STÁTUSZ',
    'stock-income.status.badge.title.stocked': 'BETÁROLVA',
    'stock-income.status.badge.title.draft': 'VÁZLAT',
};
