"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.BasicDataForm = exports.productSalesChannelOptions = void 0;
var repository_1 = require("@codebuild/glaze/libs/repository/repository");
var field_1 = require("@codebuild/sprinkles/libs/form/field");
var form_builder_1 = require("@codebuild/sprinkles/libs/form/form-builder");
var form_control_1 = require("@codebuild/sprinkles/libs/form/form-control/form-control");
var required_validator_1 = require("@codebuild/sprinkles/libs/form/validators/required.validator");
var abstract_component_1 = require("@codebuild/sprinkles/libs/libs/abstract.component");
var trans_1 = require("@codebuild/sprinkles/libs/translation/trans");
var dropdown_1 = require("@codebuild/sprinkles/modules/codebuild/drpodown/react/dropdown");
var checkbox_form_control_type_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/form-control-types/checkbox.form-control-type");
var select_input_form_control_type_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/form-control-types/select-input.form-control-type");
var text_input_form_control_type_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/form-control-types/text-input.form-control-type");
var checkbox_input_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/react/checkbox-input");
var select_input_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/react/select-input");
var text_input_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/react/text-input");
var textarea_input_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/react/textarea-input");
var lodash_1 = require("lodash");
var React = require("react");
var multi_language_input_1 = require("../../../../common/components/multi-language-input");
var form_1 = require("../../../../components/form/form");
var extended_required_validator_1 = require("../../../../components/form/validators/extended-required.validator");
var productLabelOptions = [
    { value: 'newProduct', name: 'Új termék', key: 'newProduct' },
    { value: 'newVintage', name: 'Új évjárat', key: 'newVintage' },
    { value: 'newBrew', name: 'Új főzet', key: 'newBrew' },
];
exports.productSalesChannelOptions = [
    { value: 'b2b', name: 'B2B', key: 'b2b' },
    { value: 'b2c', name: 'B2C', key: 'b2c' },
    { value: 'hiper', name: 'Hiper', key: 'hiper' },
    { value: 'horeca', name: 'Horeca', key: 'horeca' },
];
var BasicDataForm = (function (_super) {
    __extends(BasicDataForm, _super);
    function BasicDataForm() {
        var _this = this;
        var _a, _b, _c, _d, _e, _f, _g, _h, _j;
        _this = _super.apply(this, __spreadArray([], __read(arguments), false)) || this;
        _this.repository = repository_1.Repository.use('default');
        _this.state = {
            skuInfo: null,
            showHiperInputs: false
        };
        _this.form = new form_builder_1.FormBuilder({
            fields: {
                sku: new field_1.Field({
                    placeholder: '',
                    value: ((_a = _this.props.values) === null || _a === void 0 ? void 0 : _a.sku) || _this.generateSku(),
                    name: 'sku',
                    label: (0, trans_1.trans)('product.update.basic-data.form.field.sku.label'),
                }),
                title: new field_1.Field({
                    placeholder: '',
                    value: ((_b = _this.props.values) === null || _b === void 0 ? void 0 : _b.title) || '',
                    name: 'title',
                    label: React.createElement("div", null,
                        (0, trans_1.trans)('product.update.basic-data.form.field.title.label'),
                        " ",
                        React.createElement("i", { className: 'color--status-error-1' }, "*")),
                    validators: [new extended_required_validator_1.ExtendedRequiredValidator((0, trans_1.trans)('form.validation.required'))]
                }),
                subTitle: new field_1.Field({
                    placeholder: '',
                    value: ((_c = _this.props.values) === null || _c === void 0 ? void 0 : _c.subTitle) || '',
                    name: 'subTitle',
                    label: (0, trans_1.trans)('product.update.basic-data.form.field.subTitle.label'),
                    validators: []
                }),
                drs: new field_1.Field({
                    placeholder: '',
                    value: ((_d = _this.props.values) === null || _d === void 0 ? void 0 : _d.drs) || false,
                    name: 'drs',
                    label: (0, trans_1.trans)('product.update.basic-data.form.field.drs.label'),
                    validators: []
                }),
                drs2: new field_1.Field({
                    placeholder: '',
                    value: ((_e = _this.props.values) === null || _e === void 0 ? void 0 : _e.drs) || false,
                    name: 'drs',
                    label: (0, trans_1.trans)('product.update.basic-data.form.field.drs2.label'),
                    validators: []
                }),
                description: new field_1.Field({
                    placeholder: '',
                    value: ((_f = _this.props.values) === null || _f === void 0 ? void 0 : _f.description) || '',
                    name: 'description',
                    label: (0, trans_1.trans)('product.update.basic-data.form.description.name.label'),
                    validators: []
                }),
                b2cCategory: new field_1.Field({
                    placeholder: '',
                    value: '',
                    name: 'b2cCategory',
                    optionsMap: function (response) { return response.items.map(function (o) { return ({ name: o.name.hu, value: o, key: o.number }); }); },
                    optionsEndpoint: '/admin/b2c/category/',
                    loadOptionsAfterMount: true,
                    label: React.createElement("div", null,
                        (0, trans_1.trans)('product.update.basic-data.form.field.b2cCategory.label'),
                        " ",
                        React.createElement("i", { className: 'color--status-error-1' }, "*")),
                    validators: [new required_validator_1.RequiredValidator((0, trans_1.trans)('form.validation.required'))]
                }),
                manufacturer: new field_1.Field({
                    placeholder: '',
                    value: '',
                    name: 'manufacturer',
                    optionsMap: function (response) { var _a; return (_a = response.items.map(function (o) { return ({ name: o.name.hu, value: o._id, key: o._id }); })) === null || _a === void 0 ? void 0 : _a.sort(function (a, b) { var _a; return (_a = a === null || a === void 0 ? void 0 : a.name) === null || _a === void 0 ? void 0 : _a.localeCompare(b === null || b === void 0 ? void 0 : b.name, undefined, { sensitivity: 'base' }); }); },
                    optionsEndpoint: '/admin/manufacturer?limit=99999&_limit=99999&sort[name.hu]=1',
                    loadOptionsAfterMount: true,
                    label: React.createElement("div", null,
                        (0, trans_1.trans)('product.update.basic-data.form.manufacturer.name.label'),
                        " ",
                        React.createElement("i", { className: 'color--status-error-1' }, "*")),
                    validators: [new required_validator_1.RequiredValidator((0, trans_1.trans)('form.validation.required'))]
                }),
                vintage: new field_1.Field({
                    placeholder: '',
                    value: ((_g = _this.props.values) === null || _g === void 0 ? void 0 : _g.vintage) || '',
                    name: 'vintage',
                    label: (0, trans_1.trans)('product.update.basic-data.form.field.vintage.label'),
                    validators: []
                }),
                productLabel: new field_1.Field({
                    placeholder: '',
                    value: ((_h = _this.props.values) === null || _h === void 0 ? void 0 : _h.productLabel) || null,
                    name: 'productLabel',
                    label: (0, trans_1.trans)('product.update.basic-data.form.field.productLabel.label'),
                    validators: []
                }),
                salesChannels: new field_1.Field({
                    placeholder: '',
                    value: ((_j = _this.props.values) === null || _j === void 0 ? void 0 : _j.salesChannels) || [],
                    name: 'salesChannels',
                    options: exports.productSalesChannelOptions,
                    label: (0, trans_1.trans)('product.update.basic-data.form.field.salesChannels.label'),
                    validators: []
                }),
            }
        });
        return _this;
    }
    BasicDataForm.prototype.getFileNamePrefix = function (prefix) {
        if (!!prefix) {
            return prefix;
        }
        return 'product-';
    };
    BasicDataForm.prototype.componentDidMount = function () {
        var _this = this;
        var _a, _b, _c;
        var currentChannels = this.form.fields.salesChannels.getValue();
        (_b = (_a = this.props) === null || _a === void 0 ? void 0 : _a.onChannelsChange) === null || _b === void 0 ? void 0 : _b.call(_a, !!(currentChannels === null || currentChannels === void 0 ? void 0 : currentChannels.includes(exports.productSalesChannelOptions[2].value)));
        this.$subscriptions.push(this.form.fields.drs.$value.subscribe(function (drs) {
            if (!drs) {
                return;
            }
            var drs2 = _this.form.fields.drs2.getValue();
            if (drs2) {
                _this.form.fields.drs2.setValue(false);
            }
        }), this.form.fields.drs2.$value.subscribe(function (drs2) {
            if (!drs2) {
                return;
            }
            var drs = _this.form.fields.drs.getValue();
            if (drs) {
                _this.form.fields.drs.setValue(false);
            }
        }), this.form.fields.salesChannels.$value.subscribe(function (channels) {
            var _a, _b;
            (_b = (_a = _this.props) === null || _a === void 0 ? void 0 : _a.onChannelsChange) === null || _b === void 0 ? void 0 : _b.call(_a, !!(channels === null || channels === void 0 ? void 0 : channels.includes(exports.productSalesChannelOptions[2].value)));
        }), this.form.fields.b2cCategory.$value.subscribe(function (v) {
            var e_1, _a;
            var _b, _c, _d, _e, _f, _g, _h, _j, _k;
            var productLabelFilteredOptions = [];
            try {
                for (var productLabelOptions_1 = __values(productLabelOptions), productLabelOptions_1_1 = productLabelOptions_1.next(); !productLabelOptions_1_1.done; productLabelOptions_1_1 = productLabelOptions_1.next()) {
                    var o = productLabelOptions_1_1.value;
                    if (o.key === 'newProduct') {
                        productLabelFilteredOptions.push(o);
                    }
                    if (o.key === 'newVintage' && ((_e = (_d = (_c = (_b = _this.props) === null || _b === void 0 ? void 0 : _b.values) === null || _c === void 0 ? void 0 : _c.b2cCategory) === null || _d === void 0 ? void 0 : _d.slug) === null || _e === void 0 ? void 0 : _e.en) === 'wine') {
                        productLabelFilteredOptions.push(o);
                    }
                    if (o.key === 'newBrew' && ((_j = (_h = (_g = (_f = _this.props) === null || _f === void 0 ? void 0 : _f.values) === null || _g === void 0 ? void 0 : _g.b2cCategory) === null || _h === void 0 ? void 0 : _h.slug) === null || _j === void 0 ? void 0 : _j.en) === 'beer') {
                        productLabelFilteredOptions.push(o);
                    }
                }
            }
            catch (e_1_1) { e_1 = { error: e_1_1 }; }
            finally {
                try {
                    if (productLabelOptions_1_1 && !productLabelOptions_1_1.done && (_a = productLabelOptions_1.return)) _a.call(productLabelOptions_1);
                }
                finally { if (e_1) throw e_1.error; }
            }
            (_k = _this.form.fields.productLabel) === null || _k === void 0 ? void 0 : _k.setOptions(productLabelFilteredOptions);
        }));
        if (!((_c = this.props.values) === null || _c === void 0 ? void 0 : _c.sku) && !!this.props.isCreate) {
            this.$subscriptions.push(this.form.fields.b2cCategory.$value.subscribe(function (v) {
                _this.setSku();
            }), this.form.fields.vintage.$value.subscribe(function (v) {
                _this.setSku();
            }), this.form.fields.manufacturer.$value.subscribe(function (v) {
                _this.fetchSkuIndex(v);
            }));
        }
    };
    BasicDataForm.prototype.generateSku = function () {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
        var categoryNumber = ((_c = (_b = (_a = this.form) === null || _a === void 0 ? void 0 : _a.fields) === null || _b === void 0 ? void 0 : _b.b2cCategory.getValue()) === null || _c === void 0 ? void 0 : _c.number) || 'X';
        var vintage = ((_f = (_e = (_d = this.form) === null || _d === void 0 ? void 0 : _d.fields) === null || _e === void 0 ? void 0 : _e.vintage.getValue()) === null || _f === void 0 ? void 0 : _f.toString().slice(-2)) || 'XX';
        var countryCode = ((_g = this.state.skuInfo) === null || _g === void 0 ? void 0 : _g.country) || 'XX';
        var manufacturerCode = ((_h = this.state.skuInfo) === null || _h === void 0 ? void 0 : _h.code) || 'XXX';
        var index = ((_k = (_j = this.state.skuInfo) === null || _j === void 0 ? void 0 : _j.index) === null || _k === void 0 ? void 0 : _k.toString()) || 'XXXX';
        var parsedIndex = index.padStart(4, '0');
        var sku = "".concat(categoryNumber).concat(vintage).concat(countryCode).concat(manufacturerCode).concat(parsedIndex).toUpperCase();
        return sku.replace(/X+$/, '');
    };
    BasicDataForm.prototype.fetchSkuIndex = function (manufacturer) {
        return __awaiter(this, void 0, void 0, function () {
            var skuInfo, e_2;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4, this.repository.get("/admin/manufacturer/".concat(manufacturer, "/next-sku-info"))];
                    case 1:
                        skuInfo = _a.sent();
                        this.setState({ skuInfo: skuInfo }, function () { return _this.setSku(); });
                        return [3, 3];
                    case 2:
                        e_2 = _a.sent();
                        return [3, 3];
                    case 3: return [2];
                }
            });
        });
    };
    BasicDataForm.prototype.setSku = function () {
        this.form.fields.sku.setValue(this.generateSku());
    };
    BasicDataForm.prototype.componentDidUpdate = function (prevProps, prevState, snapshot) {
        var e_3, _a;
        var _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y;
        if (!(0, lodash_1.isEqual)(this.props.values, prevProps.values)) {
            this.form.fields.sku.setValue(this.props.values.sku);
            this.form.fields.title.setValue(this.props.values.title || '');
            this.form.fields.subTitle.setValue(this.props.values.subTitle);
            this.form.fields.drs.setValue(this.props.values.drs);
            this.form.fields.drs2.setValue(this.props.values.drs2);
            this.form.fields.description.setValue(this.props.values.description);
            this.form.fields.b2cCategory.setValue(((_c = (_b = this.props.values) === null || _b === void 0 ? void 0 : _b.b2cCategory) === null || _c === void 0 ? void 0 : _c._id) || ((_d = this.props.values) === null || _d === void 0 ? void 0 : _d.b2cCategory));
            this.form.fields.manufacturer.setValue(((_e = this.props.values.manufacturer) === null || _e === void 0 ? void 0 : _e._id) || this.props.values.manufacturer);
            this.form.fields.productLabel.setValue(this.props.values.productLabel);
            this.form.fields.salesChannels.setValue(this.props.values.salesChannels);
            this.form.fields.vintage.setValue((_f = this.props.values) === null || _f === void 0 ? void 0 : _f.vintage);
            (_g = this.form.fields.b2cCategory) === null || _g === void 0 ? void 0 : _g.setOptions([{
                    key: (_j = (_h = this.props.values) === null || _h === void 0 ? void 0 : _h.b2cCategory) === null || _j === void 0 ? void 0 : _j._id,
                    name: (_m = (_l = (_k = this.props.values) === null || _k === void 0 ? void 0 : _k.b2cCategory) === null || _l === void 0 ? void 0 : _l.name) === null || _m === void 0 ? void 0 : _m[this.props.editorLanguage],
                    value: (_p = (_o = this.props.values) === null || _o === void 0 ? void 0 : _o.b2cCategory) === null || _p === void 0 ? void 0 : _p._id
                }]);
            var productLabelFilteredOptions = [];
            try {
                for (var productLabelOptions_2 = __values(productLabelOptions), productLabelOptions_2_1 = productLabelOptions_2.next(); !productLabelOptions_2_1.done; productLabelOptions_2_1 = productLabelOptions_2.next()) {
                    var o = productLabelOptions_2_1.value;
                    if (o.key === 'newProduct') {
                        productLabelFilteredOptions.push(o);
                    }
                    if (o.key === 'newVintage' && ((_t = (_s = (_r = (_q = this.props) === null || _q === void 0 ? void 0 : _q.values) === null || _r === void 0 ? void 0 : _r.b2cCategory) === null || _s === void 0 ? void 0 : _s.slug) === null || _t === void 0 ? void 0 : _t.en) === 'wine') {
                        productLabelFilteredOptions.push(o);
                    }
                    if (o.key === 'newBrew' && ((_x = (_w = (_v = (_u = this.props) === null || _u === void 0 ? void 0 : _u.values) === null || _v === void 0 ? void 0 : _v.b2cCategory) === null || _w === void 0 ? void 0 : _w.slug) === null || _x === void 0 ? void 0 : _x.en) === 'beer') {
                        productLabelFilteredOptions.push(o);
                    }
                }
            }
            catch (e_3_1) { e_3 = { error: e_3_1 }; }
            finally {
                try {
                    if (productLabelOptions_2_1 && !productLabelOptions_2_1.done && (_a = productLabelOptions_2.return)) _a.call(productLabelOptions_2);
                }
                finally { if (e_3) throw e_3.error; }
            }
            (_y = this.form.fields.productLabel) === null || _y === void 0 ? void 0 : _y.setOptions(productLabelFilteredOptions);
        }
    };
    BasicDataForm.prototype.render = function () {
        return React.createElement("div", null,
            React.createElement(form_1.Form, null,
                React.createElement(form_control_1.FormControl, { field: this.form.fields.sku, type: text_input_form_control_type_1.TextInputFormControlType },
                    React.createElement(text_input_1.TextInput, { disabled: true, className: 'text-input-basic' })),
                React.createElement(form_control_1.FormControl, { field: this.form.fields.manufacturer, type: select_input_form_control_type_1.SelectInputFormControlType },
                    React.createElement(select_input_1.SelectInput, { className: 'select-input-basic', disabled: this.props.disabled || !this.props.isCreate, searchable: true })),
                React.createElement(form_control_1.FormControl, { field: this.form.fields.vintage, type: text_input_form_control_type_1.TextInputFormControlType },
                    React.createElement(text_input_1.TextInput, { type: 'number', disabled: this.props.disabled || !this.props.isCreate, maxLength: 4, className: 'text-input-basic' })),
                React.createElement(form_control_1.FormControl, { field: this.form.fields.b2cCategory, type: select_input_form_control_type_1.SelectInputFormControlType },
                    React.createElement(select_input_1.SelectInput, { disabled: this.props.disabled || !this.props.isCreate, className: 'select-input-basic' })),
                React.createElement("div", { className: "w-100 display-flex align-items-center" },
                    React.createElement("div", { className: "flex-fill" },
                        React.createElement(form_control_1.FormControl, { field: this.form.fields.productLabel, type: select_input_form_control_type_1.SelectInputFormControlType },
                            React.createElement(select_input_1.SelectInput, { disabled: this.props.disabled, unclearable: false, className: 'select-input-basic' }))),
                    this.renderLabelInfoBox()),
                React.createElement("div", { className: "w-100 display-flex align-items-center" },
                    React.createElement("div", { className: "flex-fill" },
                        React.createElement(form_control_1.FormControl, { field: this.form.fields.salesChannels, type: select_input_form_control_type_1.SelectInputFormControlType },
                            React.createElement(select_input_1.SelectInput, { disabled: this.props.disabled, unclearable: false, multiple: true, className: 'select-input-basic' })))),
                React.createElement(form_control_1.FormControl, { field: this.form.fields.title, type: text_input_form_control_type_1.TextInputFormControlType },
                    React.createElement(multi_language_input_1.MultiLanguageInput, { disabled: this.props.disabled, currentLanguage: this.props.editorLanguage, className: 'text-input-basic' })),
                React.createElement(form_control_1.FormControl, { field: this.form.fields.subTitle, type: text_input_form_control_type_1.TextInputFormControlType },
                    React.createElement(multi_language_input_1.MultiLanguageInput, { disabled: this.props.disabled, currentLanguage: this.props.editorLanguage, className: 'text-input-basic' })),
                React.createElement(form_control_1.FormControl, { field: this.form.fields.drs, type: checkbox_form_control_type_1.CheckboxFormControlType },
                    React.createElement(checkbox_input_1.CheckboxInput, { className: 'checkbox-input-basic' })),
                React.createElement(form_control_1.FormControl, { field: this.form.fields.drs2, type: checkbox_form_control_type_1.CheckboxFormControlType },
                    React.createElement(checkbox_input_1.CheckboxInput, { className: 'checkbox-input-basic' })),
                React.createElement(form_control_1.FormControl, { field: this.form.fields.description, type: text_input_form_control_type_1.TextInputFormControlType },
                    React.createElement(multi_language_input_1.MultiLanguageInput, { input: textarea_input_1.TextareaInput, disabled: this.props.disabled, currentLanguage: this.props.editorLanguage, rows: 12, className: 'textarea-input-basic' }))));
    };
    BasicDataForm.prototype.getData = function () {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function () {
            var errors, hasError;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0: return [4, this.form.validate()];
                    case 1:
                        _c.sent();
                        return [4, ((_a = this.form) === null || _a === void 0 ? void 0 : _a.getErrors())];
                    case 2:
                        errors = _c.sent();
                        hasError = false;
                        (0, lodash_1.map)(errors, function (e) {
                            hasError = hasError || !!e.length;
                        });
                        if (hasError) {
                            return [2, { error: true }];
                        }
                        return [2, __assign(__assign({}, this.form.toJSON()), { skuIndex: (_b = this.state.skuInfo) === null || _b === void 0 ? void 0 : _b.index })];
                }
            });
        });
    };
    BasicDataForm.prototype.renderLabelInfoBox = function () {
        var _a, _b, _c;
        var wineDescription = 'Új termék akkor, ha nálunk új pincészet új terméke. Új évjárat akkor, ha meglévő pincészet új évjáratú terméke.';
        var beerDescription = 'Új termék akkor, ha új főzde új terméke. Új főzet akkor, ha meglévő főzde új terméke.';
        var category = (_c = (_b = (_a = this.props.values) === null || _a === void 0 ? void 0 : _a.b2cCategory) === null || _b === void 0 ? void 0 : _b.slug) === null || _c === void 0 ? void 0 : _c.en;
        var description = null;
        switch (category) {
            case 'wine':
                description = wineDescription;
                break;
            case 'champagne':
                description = wineDescription;
                break;
            case 'beer':
                description = beerDescription;
                break;
            default:
                description = null;
                break;
        }
        if (!description) {
            return null;
        }
        return React.createElement("div", { className: "px-2" },
            React.createElement(dropdown_1.Dropdown, { renderTrigger: function () { return React.createElement("i", { className: 'cursor-pointer fas fa-info-circle' }); }, triggerType: 'hover', renderContent: function () { return React.createElement("div", { className: 'p-2 drop-shadow AttributeInfoBox' },
                    React.createElement("small", null, description)); } }));
    };
    return BasicDataForm;
}(abstract_component_1.AbstractComponent));
exports.BasicDataForm = BasicDataForm;
