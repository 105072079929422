"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.CustomFulfillment = void 0;
var field_1 = require("@codebuild/sprinkles/libs/form/field");
var form_builder_1 = require("@codebuild/sprinkles/libs/form/form-builder");
var form_control_1 = require("@codebuild/sprinkles/libs/form/form-control/form-control");
var required_validator_1 = require("@codebuild/sprinkles/libs/form/validators/required.validator");
var abstract_component_1 = require("@codebuild/sprinkles/libs/libs/abstract.component");
var trans_1 = require("@codebuild/sprinkles/libs/translation/trans");
var button_1 = require("@codebuild/sprinkles/modules/codebuild/button/react/button");
var datepicker_form_control_type_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/form-control-types/datepicker.form-control-type");
var datepicker_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/react/datepicker/datepicker");
var moment = require("moment/moment");
var React = require("react");
var CustomFulfillment = (function (_super) {
    __extends(CustomFulfillment, _super);
    function CustomFulfillment() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            error: null,
            loading: false
        };
        _this.form = new form_builder_1.FormBuilder({
            fields: {
                customFulfilmentDate: new field_1.Field({
                    name: 'customFulfilmentDate',
                    value: '',
                    label: React.createElement("div", null, (0, trans_1.trans)('b2b-order.form.label.customFulfilmentDate')),
                    validators: [new required_validator_1.RequiredValidator((0, trans_1.trans)('form.validation.required'))]
                })
            }
        });
        return _this;
    }
    CustomFulfillment.prototype.componentDidMount = function () {
        var _this = this;
        this.$subscriptions.push(this.form.fields.customFulfilmentDate.$value.subscribe(function (value) { return _this.props.onChange(value); }));
    };
    CustomFulfillment.prototype.render = function () {
        var _this = this;
        return React.createElement("div", { className: 'row' },
            React.createElement("div", { className: 'col-24 position-center pb-4 pt-1' },
                React.createElement("span", null, this.props.message)),
            React.createElement("div", { className: 'col-24 position-center pb-4 pt-1' },
                React.createElement(form_control_1.FormControl, { field: this.form.fields.customFulfilmentDate, type: datepicker_form_control_type_1.DatepickerFormControlType },
                    React.createElement(datepicker_1.Datepicker, { className: 'datepicker-basic col-12', settings: { enabledDateFrom: moment.utc().format('YYYY.MM.DD') }, type: 'date', locale: 'hu' }))),
            React.createElement("div", { className: 'row col-24 position-center' },
                React.createElement("div", { className: 'col-md-12 col-24' },
                    React.createElement(button_1.Button, { title: (0, trans_1.trans)('b2b-order.fifo.modal.close'), onClick: function () { return _this.props.onClose(); }, className: 'col button-neutral-inverze button-size-normal' })),
                React.createElement("div", { className: 'col-md-12 col-24' },
                    React.createElement(button_1.Button, { title: (0, trans_1.trans)('b2b-order.fifo.modal.confirm'), onClick: function () { return _this.props.onConfirm(_this.form.fields.customFulfilmentDate.getValue()); }, className: 'col button-neutral-inverze button-size-normal' }))));
    };
    return CustomFulfillment;
}(abstract_component_1.AbstractComponent));
exports.CustomFulfillment = CustomFulfillment;
