"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.YearSelector = void 0;
var field_1 = require("@codebuild/sprinkles/libs/form/field");
var form_builder_1 = require("@codebuild/sprinkles/libs/form/form-builder");
var form_control_1 = require("@codebuild/sprinkles/libs/form/form-control/form-control");
var abstract_component_1 = require("@codebuild/sprinkles/libs/libs/abstract.component");
var select_input_form_control_type_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/form-control-types/select-input.form-control-type");
var select_input_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/react/select-input");
var React = require("react");
var YearSelector = (function (_super) {
    __extends(YearSelector, _super);
    function YearSelector() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.form = new form_builder_1.FormBuilder({
            fields: {
                year: new field_1.Field({
                    placeholder: '',
                    value: new Date().getFullYear(),
                    name: 'year',
                    label: _this.props.label || 'Év választó',
                    options: [],
                    validators: []
                }),
            }
        });
        return _this;
    }
    YearSelector.prototype.componentDidMount = function () {
        var _this = this;
        if (!this.props.noDefault) {
            this.props.onChange(new Date().getFullYear());
            this.setOptions();
        }
        this.$subscriptions.push(this.form.fields.year.$value.subscribe(function (value) { return _this.props.onChange(value); }));
    };
    YearSelector.prototype.render = function () {
        var _a;
        return React.createElement(form_control_1.FormControl, { field: this.form.fields.year, type: select_input_form_control_type_1.SelectInputFormControlType },
            React.createElement(select_input_1.SelectInput, { searchable: false, unclearable: false, className: "select-input-basic ".concat(((_a = this.props) === null || _a === void 0 ? void 0 : _a.className) || '') }));
    };
    YearSelector.prototype.setOptions = function () {
        var currentYear = new Date().getFullYear();
        var years = Array
            .from({ length: 31 }, function (_, index) { return currentYear - index; })
            .map(function (year) { return ({
            key: year,
            value: year,
            name: year
        }); });
        this.form.fields.year.setOptions(years);
        if (!this.form.fields.year.getValue()) {
            this.form.fields.year.setValue(currentYear);
        }
    };
    return YearSelector;
}(abstract_component_1.AbstractComponent));
exports.YearSelector = YearSelector;
