"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
module.exports = {
    'document-archives.list.main.title': 'Napi mentések',
    'document-archives.list.type.label': 'Riport típus',
    'document-archives.list.from.label': 'Honnan',
    'document-archives.list.to.label': 'Hova',
    'document-archives.list.type': 'Riport típus',
    'document-archives.list.createdAt': 'Létrehozva',
    'document-archives.list.download': 'Letöltés',
    'document-archives.list.report-download': 'Leltár ív',
    'document-archives.list.inventory-report-download': 'Készletbevallás',
    'document-archives.type.daily-stock-csv': 'Napi CSV mentés',
    'document-archives.type.daily-stock-pdf': 'Napi PDF mentés',
    'document-archives.type.daily-inventory-report-pdf': 'Napi készletbevallás',
};
